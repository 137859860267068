import BlogSwiper from "@/components/Shared/BlogSwiper";
import CustomSourceFlowText from "@/components/Shared/CustomSourceflowText";
import CustomLink from "@/components/Buttons/CustomLink";
import useBlogs from "@/hooks/useBlogs";
import useLocale from "@/hooks/useLocale";
import localizePath from "@/functions/localizePath";

export default function BlogsSection({
  title = "Industry insights, trends, podcasts and more.",
  styles,
  path,
  type = "Spotlight",
}) {
  const locale = useLocale();
  const blogs = useBlogs().filter(({ content_type }) => content_type === type);

  if (blogs.length === 0) {
    return null;
  }

  return (
    <div className="container w-full m-auto pt-16 md:pt-[98px] pb-16 md:pb-[100px]">
      <div className="flex flex-wrap justify-between items-end">
        <p
          className={`${styles.txt} text-3xl md:text-[50px]/[60px] max-w-[840px] w-full pb-[30px] md:pe-[30PX]`}
        >
          <CustomSourceFlowText
            title={title}
            path={`${path}.blogheader1`}
            locale={locale}
          />
        </p>
        <div className="flex justify-between lg:items-center pb-[46px] flex-col gap-4 lg:gap-0 lg:flex-row">
          <CustomLink
            href={localizePath("/newsroom", locale)}
            color={styles.active}
            styles={`${styles.txt} text-sm font-medium md:text-xl`}
          >
            <CustomSourceFlowText
              title="View all blogs"
              path={`${path}.bloglink1`}
              locale={locale}
            />
          </CustomLink>
        </div>
      </div>
      <BlogSwiper style={styles} activeContent={blogs} />
    </div>
  );
}
