import { COLOR_VARIANTS } from "@/constants";
import cn from "classnames";

export default function CustomLink({ href, children, color = "blue", styles }) {
  return (
    <div className={`${COLOR_VARIANTS[color]} group w-max`}>
      <a
        className={cn(
          COLOR_VARIANTS[color],
          styles,
          `group text-decoration-none`,
          {
            "group-hover:text-purple": color === "purple",
            "group-hover:text-white": color === "white",
            "group-hover:text-blue": color === "blue",
          },
        )}
        href={href}
      >
        {children}
        <span
          className={cn(
            `w-0 h-[1px] mt-[-10px] transition-all block group-hover:w-full`,
            {
              "bg-purple": color === "purple",
              "bg-white": color === "white",
              "bg-blue": color === "blue",
            },
          )}
        />
      </a>
    </div>
  );
}
